import {graphql} from 'gatsby'
import ContactSection from './../components/contact-section'
import HoldingPageHeroImage from './../components/holding-page-hero-image'
import Layout from '../components/layout'
import ProjectSection from './../components/project-section'
import React, {useState} from 'react'
import {HOLDING_PAGE_CSS_MODIFIER, PAGE_ROOT_ELEMENT_ID, PROJECT_SECTION_CSS_MODIFIER} from './../constants/global-constants'

export default props => {
  const {data} = props
  const prismicData = {
    showTheHoldingPage: data.prismicHoldingPage.data.show_the_holding_page,
    slug: data.project.uid,
  }

  return (
    <Layout
      documentTitlePrefix={`${data.project.data.project_name.text} | PROJECT`}
      isIndexPage={false}
      pageName="projects"
      showScrollToTopBtn={false}
      showTheHoldingPage={prismicData.showTheHoldingPage}
    >
      {prismicData.showTheHoldingPage ? (
        <>
          <HoldingPageHeroImage />
          <ContactSection cssModifier={HOLDING_PAGE_CSS_MODIFIER} />
        </>
      ) : (
        <>
          <ProjectSection cssModifier={PROJECT_SECTION_CSS_MODIFIER} slug={data.project.uid} data={data.project.data} />
        </>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query Project($slug: String) {
    project: prismicProject(uid: {eq: $slug}) {
      uid
      data {
        photo_gallery {
          photo {
            alt
            url
            dimensions {
              height
              width
            }
            thumbnails {
              thumbnail {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          comment {
            text
          }
          gallery_item_enabled
        }
        is_completed
        location {
          text
        }
        primary_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        project_date
        project_description {
          html
        }
        project_name {
          text
        }
      }
    }
    prismicHoldingPage {
      data {
        show_the_holding_page
      }
    }
  }
`
