import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'
import {MONTHS_SHORT} from './../constants/global-constants'

export default function LocationAndDate({location, date}) {
  const dateObj = new Date(date)
  const formattedDate = `${MONTHS_SHORT[dateObj.getMonth()]} ${dateObj.getFullYear()}`

  return (
    <StyledComponent className="location-and-date">
      {location && formattedDate && (
        <Fade>
          <p>
            {location} &ndash; {formattedDate}
          </p>
        </Fade>
      )}
      {!location && formattedDate && (
        <Fade>
          <p>{formattedDate}</p>
        </Fade>
      )}
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin-bottom: 2rem;
`
