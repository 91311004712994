import {SRLWrapper} from 'simple-react-lightbox'
import GalleryThumbnail from './gallery-thumbnail'
import React from 'react'
import styled from 'styled-components'
import {SIMPLE_REACT_LIGHTBOX_OPTIONS} from './../constants/global-constants'

// Simple React Lightbox (SRL) has been deprecated
// But I'm using it anyway!
// https://www.npmjs.com/package/simple-react-lightbox

export default function PhotoGallery({cssModifier, data}) {
  const filteredData = data.filter(item => item.gallery_item_enabled)
  const numImages = filteredData.length
  return (
    <SRLWrapper options={SIMPLE_REACT_LIGHTBOX_OPTIONS}>
      <StyledComponent>
        {filteredData.map((item, index) => (
          <GalleryThumbnail key={index} data={item} index={index} numImages={numImages} />
        ))}
      </StyledComponent>
    </SRLWrapper>
  )
}

const StyledComponent = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 4rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 4rem;

  > * {
    align-items: center;
    aspect-ratio: 7/5;
    display: flex;
    justify-content: center;
  }
`
