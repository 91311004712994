import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'

export default function GalleryThumbnail({data, index, numImages}) {
  const prismicData = {
    comment: data.comment.text || '',
    fullImageAlt: data.photo.alt,
    fullImageUrl: data.photo.url,
    fullImageWidth: data.photo.dimensions?.width,
    fullImageHeight: data.photo.dimensions?.height,
    thumbnailAlt: data.photo.thumbnails.thumbnail.alt,
    thumbnailUrl: data.photo.thumbnails.thumbnail.url,
    thumbnailWidth: data.photo.thumbnails.thumbnail.dimensions?.width,
    thumbnailHeight: data.photo.thumbnails.thumbnail.dimensions?.height,
  }

  return (
    <StyledComponent className="gallery-thumbnail">
      <Fade>
        <a href={prismicData.fullImageUrl} className="link">
          <img
            src={prismicData.thumbnailUrl}
            alt={prismicData.comment !== '' ? `${prismicData.comment}` : ``}
            width={prismicData.thumbnailWidth}
            height={prismicData.thumbnailHeight}
          />
        </a>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.li`
  background-color: #eeeeee;
  border: 1rem solid white;
  overflow: hidden;
  position: relative;

  .link {
    display: block;
    height: 100%;
    width: 100%;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  img {
    display: block;
    height: 100%;
    transform: scale(1);
    transition: transform 0.2s cubic-bezier(0.65, 0, 0.35, 1);
    width: 100%;
  }
`
