import {Link} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import LocationAndDate from './location-and-date'
import PageHeading from './page-heading'
import PhotoGallery from './photo-gallery'
import ProjectName from './project-name'
import React from 'react'
import styled from 'styled-components'
import TextBlock from './text-block'

export default function ProjectSection({cssModifier, slug, data}) {
  const currentDate = new Date()
  const currentDateStr = currentDate.toISOString().slice(0, 10)

  const prismicData = {
    name: data.project_name.text,
    date: data.project_date || currentDateStr,
    location: data.location.text,
    description: data.project_description.html,
    photoGalleryData: data.photo_gallery,
  }

  prismicData.photoGalleryData = prismicData.photoGalleryData.filter(item => item.photo.url)

  const onBackLinkClick = event => {
    window.history.back()
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <PageHeading heading="Project" />
      <Container type="wide">
        <div className="row">
          <Fade>
            <a onClick={onBackLinkClick} className="back-link">
              Back
            </a>
          </Fade>
          <ProjectName name={prismicData.name} />
          <LocationAndDate location={prismicData.location} date={prismicData.date} />
          {prismicData.description && (
            <div className="description-text-wrapper">
              <TextBlock cssModifier="description-text">
                <div dangerouslySetInnerHTML={{__html: prismicData.description}} />
              </TextBlock>
            </div>
          )}
          {prismicData.photoGalleryData.length > 0 && (
            <PhotoGallery cssModifier={cssModifier} data={prismicData.photoGalleryData} />
          )}
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;

  .description-text-wrapper {
    margin-bottom: 3rem;
    max-width: 87rem;
  }

  .row {
    margin: 0 auto;
    max-width: 90rem;
  }

  .intro-text-wrapper {
    margin-bottom: 4rem;
    max-width: 87rem;
  }

  .back-link {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 115'%3E%3Cpath fill='%236b6b6b' d='M0 58 100 0v115L0 58Z'/%3E%3C/svg%3E");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: 0.7rem auto;
    color: #000;
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 1rem 0;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }
  }
`
